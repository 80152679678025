import React from 'react';
import HTML from '../sources/html.png';
import CSS from '../sources/css.png';
import JavaScript from '../sources/javascript.png';
import ReactImg from '../sources/react.png';
import Node from '../sources/node.png';
import FireBase from '../sources/firebase.png';
import GitHub from '../sources/github.png';
import Java from '../sources/java.png';
import Python from '../sources/python.png';
const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#f4f1de] text-[#3d405b]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
          <div>
              <p className='text-4xl font-bold inline border-b-4 border-[#e07a5f] '>Skills</p>
              <p className='py-4'>These are some technologies I've worked with</p>
          </div>

          <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
              <div className='shadow-md shadow-[#00bbf9] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={ReactImg} alt="HTML icon" />
                  <p className='my-4'>REACT</p>
              </div>
              <div className='shadow-md shadow-[#e07a5f] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Java} alt="HTML icon" />
                  <p className='my-4'>Java</p>
              </div>
              <div className='shadow-md shadow-[#ffca3a] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Python} alt="HTML icon" />
                  <p className='my-4'>Python</p>
              </div>
              <div className='shadow-md shadow-[#293241] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={JavaScript} alt="HTML icon" />
                  <p className='my-4'>JAVASCRIPT</p>
              </div>
              <div className='shadow-md shadow-[#faa307] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={FireBase} alt="HTML icon" />
                  <p className='my-4'>Firebase</p>
              </div>
              <div className='shadow-md shadow-[#dc2f02] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={HTML} alt="HTML icon" />
                  <p className='my-4'>HTML</p>
              </div>
              <div className='shadow-md shadow-[#1982c4] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={CSS} alt="HTML icon" />
                  <p className='my-4'>CSS</p>
              </div>
              <div className='shadow-md shadow-[#76c893] hover:scale-110 duration-500'>
                  <img className='w-20 mx-auto' src={Node} alt="HTML icon" />
                  <p className='my-4'>NODE JS</p>
              </div>
          </div>
      </div>
    </div>
  );
};

export default Skills;