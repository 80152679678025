import React from 'react';

const Courses = () => {
  return (
    <div name='courses' className='w-full md:h-screen text-[#3d405b] bg-[#f4f1de]'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 text-[#3d405b] border-[#e07a5f]'>
                    Courses
                </p>
                <p className='py-6'>This is the list of some relevant courses which I have completed : </p>
            </div>
            <div className='grid grid-cols-2 gap-4' >
                <h3>CSC 225 - Data Structures and Algorithms</h3>
                <h3>SENG 265 - Software Development Methods</h3>
                <h3>CSC 130 - Web and Mobile Applications Development</h3>
                <h3>CSC 115 - Fundamentals of Programming II</h3>
                <h4>CSC 110 - Fundamentals of Programming I</h4>
                <h4>CSC 106 - The practice of Computer Science</h4>
                <h4>MATH 122 - Logic and Foundation</h4>
                <h4>STAT 260 - Probability and Statistics I</h4>
                <h4>MATH 101&109 - Calculus I&II</h4>
                <h4>MATH 211 - Matrix Algebra</h4>
            </div>
        </div>
    </div>
  );
};

export default Courses;