import React from 'react';

const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-[#f4f1de] flex justify-center items-center p-4'>
        <form method='POST' action="https://getform.io/f/438b3740-32f7-47a7-aafc-19385814c009" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-[#e07a5f] text-[#3d405b]'>Contact</p>
                <p className='text-[#3d405b] py-4'>Submit the form below to connect or shoot me an email - sakshamdua103@gmail.com</p>
            </div>
            <input className='bg-[#f2cc8f] p-2 focus:bg-[white]' type="text" placeholder='Name' name='name' />
            <input className='my-4 p-2 bg-[#f2cc8f] focus:bg-[white]' type="email" placeholder='Email' name='email' />
            <textarea className='bg-[#f2cc8f] p-2 focus:bg-[white]' name="message" rows="10" placeholder='Message'></textarea>
            <button className='text-[#3d405b] bg-[#81b29a] border-2 hover:bg-[#e07a5f] hover:text-[white] hover:border-[#e07a5f] px-4 py-3 my-8 mx-auto flex items-center'>Connect!</button>
        </form>
    </div>
  );
};

export default Contact;