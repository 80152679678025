import React from 'react';
import {HiArrowNarrowRight} from 'react-icons/hi';
import bgim from '../sources/bg1.svg';
import { Link } from 'react-scroll';
const Home = () => {
  return (
    <div name="home" className='bg-[#f4f1de] w-full h-screen'>
        
        <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
            <p className='text-[#3d405b]'>Hi, My name is</p>
            <h1 className='text-4xl sm:text-7xl font-bold text-[#e07a5f]'>Saksham Dua</h1>
            <h2 className='text-4xl sm:text-5xl font-bold text-[#3d405b]'>I'm a Full Stack Software Developer</h2>
            <p className='text-[#3d405b] max-w-[700px] py-4'>I'm a software developer and I will be starting my professional software development Journey this Fall 2022,
                Really Excited to see what awaits for me in the future, Open to Oppurtunities!
            </p>
        <div className='flex gap-5'>
            <Link to="projects" smooth={true}><button className='text-white bg-[#f2cc8f] group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#81b29a] hover:border-[#81b29a]'>See Projects <span className='group-hover:rotate-90 duration-300'><HiArrowNarrowRight className='ml-3'/></span>  </button></Link>
        </div>
        
        </div>
    </div>
  );
};

export default Home;