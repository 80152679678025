import React from 'react';
import WorkImg from '../sources/workImg.jpeg';
import realEstate from '../sources/realestate.jpg';
import three from '../sources/three.jpg';
const Projects = () => {
  return (
    <div name='projects' className='w-full md:h-screen text-[#3d405b] bg-[#f4f1de]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-[#3d405b] border-[#e07a5f]'>
            Projects
          </p>
          <p className='py-6'>These are some recent Projects I have worked on!</p>
        </div>

{/* Container */}
        <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4'>

            {/* Grid Item */}
          <div 
            style={{ backgroundImage: `url(${WorkImg})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div hover:scale-110 opacity-100 duration-500'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl text-center font-bold text-[#3d405b] tracking-wider'>
                Clusters
              </span>
              <span>
                (Desktop Only)
              </span>
              <div className='pt-8 text-center'>
                <a href='https://clusters.info/'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Visit
                  </button>
                </a>
                <a href='https://github.com/Saksham1009/Clusters'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${realEstate})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div hover:scale-110 opacity-100 duration-500'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-[#3d405b] tracking-wider'>
                OTT BOX
              </span>
              <div className='pt-8 text-center'>
                <a href='https://webhome.csc.uvic.ca/~sdua/homepage.html'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Visit
                  </button>
                </a>
                <a href='https://github.com/Saksham1009/Movie-Suggestion-Website'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
            {/* Grid Item */}
          <div
            style={{ backgroundImage: `url(${three})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div hover:scale-110 opacity-100 duration-500'
          >
            {/* Hover Effects */}
            <div className='opacity-0 group-hover:opacity-100'>
              <span className='text-2xl font-bold text-[#3d405b] tracking-wider'>
                Food Ordering Interface
              </span>
              <div className='pt-8 text-center'>
                <a href='https://foodapp-a4954.web.app'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Visit
                  </button>
                </a>
                <a href='https://github.com/Saksham1009/Food-Ordering-Interface'>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>
                    Code
                  </button>
                </a>
              </div>
            </div>
          </div>
          <a href="https://github.com/Saksham1009">
          <button className='text-white bg-[#e07a5f] group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#81b29a] hover:border-[#81b29a] hover:scale-110 duration-500'>More Projects</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projects;