import React, { useState } from 'react';
import {FaBars, FaFacebook, FaTimes} from 'react-icons/fa';
import Logo from '../sources/logo.png';
import { FaLinkedin } from 'react-icons/fa';
import { FaGithub } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import {BsFillPersonLinesFill} from 'react-icons/bs';
import { Link } from 'react-scroll';
const Nav = () => {
    const [nav,setNav] = useState(false);
    const handleClick = () => {
        setNav(!nav);
    }
  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#f4f1de] text-[#3d405b] z-10'>
        <div>
            {/* <img src={Logo} alt="Logo" style={{width:'50px'}}/> */}
            <div class="wrapper">
    <ul class="dynamic-txts">
      <li><span>Sdua</span></li>
      <li><span>sDua</span></li>
      <li><span>sdUa</span></li>
      <li><span>sduA</span></li>
    </ul>
  </div>
        </div>
                
        <ul className='hidden md:flex font-bold'>
            {/* this means if the display is of medium size then flex otherwise hidden (Compressed media query) */}



            {/* <li className='py-6 text-4xl'>Home</li>
            <li className='py-6 text-4xl'>About</li>
            <li className='py-6 text-4xl'>Experience</li>
            <li className='py-6 text-4xl'>Skills</li>
            <li className='py-6 text-4xl'>Projects</li>
            <li className='py-6 text-4xl'>Courses</li>
            <li className='py-6 text-4xl'>Contact</li> */}



            <li>
            <Link to='home' smooth={true} duration={500}>
                Home
            </Link>
            </li>
            <li>
            <Link to='about' smooth={true} duration={500}>
                About
            </Link>
            </li>
            <li>
            <Link to='skills' smooth={true} duration={500}>
                Skills
            </Link>
            </li>
            <li>
            <Link to='projects' smooth={true} duration={500}>
                Projects
            </Link>
            </li>
            <li>
            <Link to='courses' smooth={true} duration={500}>
                Courses
            </Link>
            </li>
            <li>
            <Link to='contact' smooth={true} duration={500}>
                Contact
            </Link>
            </li>
        </ul>
        <div onClick={()=>{
            return setNav(!nav);
        }} className='md:hidden z-10'>
            {!nav && <FaBars />}
            {nav && <FaTimes/>}
        </div>
        <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#f4f1de] flex flex-col justify-center items-center'}>
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='home' smooth={true} duration={500}>
            Home
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <a onClick={handleClick} href="https://drive.google.com/file/d/1T1AN1HjiE7B_rWsPFV676IyhYOqMzpHX/view?usp=sharing">
            Resume
          </a>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='about' smooth={true} duration={500}>
            About
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='skills' smooth={true} duration={500}>
            Skills
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='projects' smooth={true} duration={500}>
            Projects
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='courses' smooth={true} duration={500}>
            Courses
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='contact' smooth={true} duration={500}>
            Contact
          </Link>
        </li>
        </ul>
        {/* Icons down */}
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                    <a href='https://www.linkedin.com/in/saksham-dua-ab78451ba/' className='flex justify-between items-center w-full text-[white]'>
                        Linked
                        <FaLinkedin size={30}/>
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[black]'>
                    <a href='https://github.com/Saksham1009' className='flex justify-between items-center w-full text-[white]'>
                        GitHub
                        <FaGithub size={30}/>
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#f4d35e]'>
                    <a href='https://leetcode.com/SakshamDua/' className='flex justify-between items-center w-full text-[white]'>
                        Leet
                        <img src={"https://upload.wikimedia.org/wikipedia/commons/1/19/LeetCode_logo_black.png"} style={{height:'30px',width:'30px'}}/>
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
                    <a href='https://drive.google.com/file/d/1T1AN1HjiE7B_rWsPFV676IyhYOqMzpHX/view?usp=sharing' className='flex justify-between items-center w-full text-[white] text-[20px]'>
                        Resume
                        <BsFillPersonLinesFill size={30}/>
                    </a>
                </li>
            </ul>
        </div>
    </div>
  );
};

export default Nav;